import React, { useRef } from 'react';

import dataApi from '../api/data-api';
import {useAuth} from './AuthContext';
import { useData } from './DataContext';
import Center from './Center';

const style = {
  data: {
    width: '1200px',
    height: '800px'
  }
};

export default function AdminPage() {
  const pwdRef = useRef();
  const dataRef = useRef();
  const {isAuthenticated, checkAuth} = useAuth();
  const data = useData();

  const checkPassword = () => {
    checkAuth(pwdRef.current.value).then(res => console.log('check: ', res.data));
  };

  const updateData = () => {
    if(dataRef.current.value){
      const data = JSON.parse(dataRef.current.value);
      dataApi.post(data).then(res => console.log(res.data));
    }
  };

  return (
    <Center className='h-screen w-screen'>
    {
      !isAuthenticated
      ? 
      <div>
        <input type="password" placeholder='Password' ref={pwdRef} />
        <button onClick={checkPassword}>Confirm</button>
      </div>
      : 
      <div>
        <textarea style={style.data} ref={dataRef} defaultValue={JSON.stringify(data, null, 2)} />
        <div>
          <button onClick={updateData}>Update</button>
        </div>
      </div>
    }
    </Center>
  )
}
