import classNames from 'classnames';
import {motion} from 'framer-motion';
import { Children } from 'react';

const listMotion = {
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren"
    }
  },
  visible: {
    opacity: 1,
    transition:{
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const itemMotion = {
  hidden: {scale: 0},
  visible: {scale: 1}
};

export default function GridList({items, keyExpr, children, gridClass="grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3"}) {
  return (
    !items
    ? null
    : <div>
      <motion.ul initial="hidden" animate="visible" variants={listMotion}>
        <div className={classNames(`grid`, gridClass)}>
        {
          items.map((item, idx) => <motion.li variants={itemMotion} key={keyExpr ? item[keyExpr] : idx}>{children(item)}</motion.li>)
        }
        </div>
      </motion.ul>
    </div>
  )
}
