

import logoImage from '../image/logo.png';

const style = {
  logo: {width: '64px'}
};

export default function Logo({width}) {
  return (
    <img src={logoImage} style={width ? {...style.logo, width} : style.logo} alt="mkcreative logo" />
  )
}
