import axios from 'axios';

const api = {
  checkAuth
};

export default api;

const URL = '/api/auth';

function checkAuth(data){
  return axios.post(URL, data);
}