import React, { useState } from 'react';

import authApi from '../api/auth-api';

const AuthContext = React.createContext();

const AuthContextProvider = ({children}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const checkAuth = (password) => authApi.checkAuth({password}).then(res => {
    setIsAuthenticated(res.data);
    return res.data;
  });
  
  return (
    <AuthContext.Provider value={{isAuthenticated, checkAuth}}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if(!context){
    throw new Error("useAuth should be used within AuthContextProvider");
  }
  return context;
};

export {AuthContextProvider, useAuth};