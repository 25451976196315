import axios from 'axios';

const api = {
  get,
  post
};

export default api;

const URL = '/api/data';

function get(){
  return axios.get(URL);
}

function post(data){
  return axios.post(URL, data);
}