
import classNames from "classnames"

export default function Center({children, centerH=true, centerV=true, className}) {
  return (
    <div className={classNames("flex", {'justify-center': centerH, 'items-center': centerV}, className)}>
      {children}
    </div>
  )
}
