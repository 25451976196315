import {Routes, Route} from 'react-router-dom';

import './App.css';
import { DataContextProvider } from './component/DataContext';
import { AuthContextProvider } from './component/AuthContext';
import HomePage from './component/HomePage';
import AdminPage from './component/AdminPage';

function App() {
  
  return (
    <DataContextProvider>
    <AuthContextProvider>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/Admin" element={<AdminPage />} />
      </Routes>
    </AuthContextProvider>
    </DataContextProvider>
  );
}

export default App;
