import { useState } from 'react';
import classNames from 'classnames';
import {motion, AnimatePresence} from 'framer-motion';

import { useData } from "./DataContext"; 
import Logo from "./Logo";
import Center from "./Center";
import Image from "./Image";
import GridList from './GridList';
import MotionFadeIn from './MotionFadeIn';

const style = {
  intro: {width: '100%', maxWidth: '710px'},
};

export default function HomePage() {
  const {home} = useData();
  const [selectedImage, setSelectedImage] = useState();

  const handleClickImage = image => {
    setSelectedImage(image);
  };

  const replaceContactInfo = text => text
    .replace('{email}', `<a href="mailto:${home.email}" class='link'>${home.email}</a>`)
    .replace('{instagram}', `<a href="https://www.instagram.com/${home.instagram}/" target="_blank" class='link'>Instagram</a>`);

  return (
    <div className="p-5 max-w-5xl mx-auto">
      <Logo />
      {
        home &&
        <>
          <Center className="mb-10">
            <MotionFadeIn>
              <Image src={home.myImage} />
              <div>{home.myTitle}</div>
            </MotionFadeIn>
          </Center>
          <MotionFadeIn className="my-5 mx-auto" style={style.intro}>
            {home.intro.map((d, i) => <div key={i} className={classNames({'h-5': !d})}>{d}</div>)}
            {<div dangerouslySetInnerHTML={{__html: replaceContactInfo(home.contactInfo)}} />}
          </MotionFadeIn>
          <Center className="text-xl mt-14 mb-5">{home && home.imageTitle}</Center>
          <div className="md:flex justify-center">
            <GridList items={home.images}>
            {
              img => <motion.div layoutId={img.src} ><ImageCard image={img} onClick={handleClickImage} /></motion.div>
            }
            </GridList>
            <AnimatePresence>
            {
              selectedImage &&
              <motion.div layoutId={selectedImage.src} className="fixed lef-0 top-0 my-10 w-11/12 max-w-3xl z-30 shadow-2xl rounded-md" >
                <div className='relativee'>
                  <button className='bg-black w-8 h-8 text-white hover:bg-purple-400 rounded-full absolute -top-3 -right-3 cursor-pointer z-40' onClick={() => setSelectedImage(null)}>X</button>
                  <Image src={selectedImage.srcBig}/>
                </div>
              </motion.div>
            }
            </AnimatePresence>
          </div>
        </>
      }
    </div>
  );
}

const hoverMotion = {
  scale: 1.05
};
function ImageCard({image, onClick}){
  return (
    <motion.div onClick={() => onClick(image)} whileHover={hoverMotion}>
      <Image src={image.srcBig} className='cursor-pointer' />
      <div className="text-center mb-7">{image.text}</div>
    </motion.div>
  );
}