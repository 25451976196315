import React, { useEffect, useState } from 'react';

import dataApi from '../api/data-api';

const DataContext = React.createContext();

const DataContextProvider = ({children}) => {
  const [data, setData] = useState({});

  useEffect(() => {
    dataApi.get().then(res => setData(res.data));
  }, []);

  return (
    <DataContext.Provider value={data}>
      {children}
    </DataContext.Provider>
  );
};

const useData = () => {
  const context = React.useContext(DataContext);
  if(!context){
    throw new Error("useData should be used within DataContextProvider");
  }
  return context;
};

export {DataContextProvider, useData};